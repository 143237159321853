import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
//import React from 'react';
//import DnbStatusLink from './components/dnb-status-link/DnbStatusLink';

// the translations
// (tip move them in a JSON file and import them)
const norwegian = {
  translation: {
    'We will be undergoing scheduled maintenance during this time': 'Vi vil gjennomføre planlagt vedlikehold under denne tiden',
    'Det er for tiden problemer med konto/saldo på mobil.': 'Det er for tiden problemer med konto/saldo på mobil.',
    'Andre tjenester privat': 'Andre tjenester privat',
    'Andre tjenester privat': 'Andre Tjenester Privat',
    'Andre tjenester bedrift': 'Andre tjenester bedrift',
    'Andre tjenester bedrift': 'Andre Tjenester Bedrift',
    'Scheduled maintenance is currently in progress. We will provide updates as necessary.': 'Vedlikehold pågår, vi oppdaterer etter behov.',
    'We are continuing to investigate this issue.': 'Det jobbes fortsatt med å løse feilen.',
    'We are currently investigating this issue.': 'Det jobbes med problemet.',
    'We are working on solving the incident.': 'Det jobbes med å løse problemet så raskt som mulig.',
    'This incident has been resolved.': 'Dette problemet er løst.',
    'Corporate Internet banking': 'Nettbank Bedrift',
    'Corporate Internet Banking': 'Nettbank Bedrift',
    'Account Overview': 'Kontooversikt',
    'International Payments': 'Betaling Utland',
    Transactions: 'Transaksjoner',
    'Payments Overview': 'Betalingsoversikt',
    Payments: 'Betaling',
    'DNB Bedrift': 'Mobilbank Bedrift',
    Personal: 'Privat',
    Business: 'Bedrift',
    Loading: 'Laster',
    'All our systems and services are working as normal': 'Alle våre systemer fungerer normalt',
    'See incidents': 'Se hendelser',
    'Last updated': 'Sist oppdatert',
    'Go to the incident overview': 'Gå til hendelsesoversikt',
    incidents: 'hendelser',
    Incidents: 'Hendelser',
    incident: 'hendelse',
    Incident: 'Hendelse',
    resolved: 'løst',
    started: 'oppstod',
    'Last 48 hours': 'Siste 48 timer',
    'last 24 hours': 'siste 24 timer',
    'Last 90 days': 'Siste 90 dager',
    Systems: 'Systemer',
    working: 'fungerer',
    'There is currently no scheduled maintenance': 'Det er for tiden ingen planlagte vedlikehold',
    'Scheduled maintenance': 'Planlagt vedlikehold',
    'days since': 'dager siden',
    last: 'siste',
    'No incidents in': 'Ingen hendelser på',
    over: 'over',
    months: 'måneder',
    Nettbank: 'Nettbank',
    'Kontoer/balanse Nett': 'Kontoer/balanse',
    'Overføringer Nett': 'Overføringer',
    'Betalinger Nett': 'Betalinger',
    'Minibanker, Kort Og Betalingsterminaler': 'Minibanker, Kort Og Betalingsterminaler',
    'Minibanker, kort og betalingsterminaler': 'Minibanker, Kort Og Betalingsterminaler',
    Kort: 'Kort',
    Innlogging: 'Innlogging',

    'BankID (OIDC)': 'BankID på DNBs mobilapplikasjoner',
    DigiPass: 'Innlogging uten BankID',
    GO3Phonecode: 'Personlig kode',

    Mobilbank: 'Mobilbank',
    'Sbanken Mobilbank': 'Sbanken Mobilbank',
    'Betalinger Mobil': 'Betalinger',
    'Kontoer/Balanse Mobil': 'Kontoer/balanse',
    'Overføringer Mobil': 'Overføringer',
    'Regninger Mobil': 'Regninger',

    operational: 'Normal drift',
    minor: 'Redusert funksjonalitet',
    major: 'Delvis utilgjengelig',
    critical: 'Utilgjengelig',
    under_maintenance: 'Vedlikehold',

    'Affected systems': 'System(er) som vil bli påvirket',
    //Header.js
    'DNB Status front page': 'Forsiden DNB Status',
    'Go to dnb.no': 'Gå til dnb.no',
    // HistoryHeader.js
    'Back to the front page': 'Gå tilbake til startsiden',
    Back: 'Tilbake',
    'Subscribe to updates': 'Abonnér på oppdateringer',
    History: 'Historikk',

    // HistoricIncidentCard.js
    'Show log': 'Vis logg',
    'Hide log': 'Skjul logg',
    affect: 'påvirker',
    affected: 'påvirket',
    // IncidentsListWrapper.js
    'Ongoing incidents': 'Aktive hendelser',

    // NoIncident.js
    'Not much is happening here': 'Her skjer det ikke stort',

    // IncidentsNoIncidents.js
    'No incidents in the last 48 hours': 'Ingen hendelser de siste 48 timene',
  }
};

const resources = {
  en: {
    translation: {
      //'Vi jobber med problemet.': 'We are working on solving the incident.',
      'We will be undergoing scheduled maintenance during this time': 'We will be undergoing scheduled maintenance during this time',
      'Det er for tiden problemer med konto/saldo på mobil.':
      'We are currently experiencing issues with accounts / balances on Mobile.',
      // Components
      'Minibanker, kort og betalingsterminaler': 'Cash Machines/ATMs, Cards, And Payment Terminals',
      'Andre tjenester privat': 'Other Services Personal',
      'Andre Tjenester Privat': 'Other Services Personal',
      'Andre tjenester bedrift': 'Other Services Business',
      'Andre Tjenester Bedrift': 'Other Services Business',
      Kort: 'Cards',

      Innlogging: 'Login',
      'DNB Bedrift': 'Corporate Mobile Bank',

      'BankID (OIDC)': 'BankID (OIDC)',
      DigiPass: 'DigiPass',
      GO3Phonecode: 'GO3Phonecode',

      Mobilbank: 'Mobile Bank',
      'Sbanken Mobilbank': 'Sbanken Mobile Bank',
      'Betalinger Mobil': 'Payments',
      'Kontoer/Balanse Mobil': 'Accounts/balance',
      'Overføringer Mobil': 'Transactions',
      'Regninger Mobil': 'Invoices/Bills',

      Nettbank: 'Internet Bank',
      'Kontoer/balanse Nett': 'Accounts/balance',
      'Overføringer Nett': 'Transactions',
      'Betalinger Nett': 'Payments',

      'Minibanker, Kort Og Betalingsterminaler': 'Cash Machines/ATMs, Cards, And Payment Terminals',
      'Corporate Internet banking': 'Corporate Internet Banking',
      // End components

      Personal: 'Personal',
      Business: 'Business',
      Loading: 'Loading',
      'All our systems and services are working as normal': 'All our systems and services are working as normal',
      'See incidents': 'See incidents',
      'Last updated': 'Last updated',
      'Go to the incident overview': 'Go to the incident overview',
      incidents: 'incidents',
      Incidents: 'Incidents',
      incident: 'incident',
      Incident: 'Incident',
      resolved: 'resolved',
      started: 'started',
      'Last 48 hours': 'Last 48 hours',
      'last 24 hours': 'last 24 hours',
      'Last 90 days': 'Last 90 days',
      Systems: 'Systems',
      working: 'working',
      'There is currently no scheduled maintenance': 'There is currently no scheduled maintenance',
      'Scheduled maintenance': 'Scheduled maintenance',
      'days since': 'days since',
      last: 'last',
      'No incidents in': 'No incidents in', //No none,
      over: 'over',
      months: 'months',
      
      
      'Scheduled maintenance is currently in progress. We will provide updates as necessary.':
        'Scheduled maintenance is currently in progress. We will provide updates as necessary.',

      'This incident has been resolved.': 'This incident has been resolved.',
      
      
      operational: 'Operational',
      minor: 'Degraded performance',
      major: 'Partial outage',
      critical: 'Major outage',
      under_maintenance: 'Under maintenance',

      'Affected systems': 'Affected systems',
      // Header.js
      'DNB Status front page': 'DNB Status front page',
      'Go to dnb.no': 'Go to dnb.no',
      // HistoryHeader.js
      'Back to the front page': 'Back to the front page',
      Back: 'Back',
      'Subscribe to updates': 'Subscribe to updates',
      History: 'History',

      // HistoricIncidentCard.js
      'Show log': 'Show log',
      'Hide log': 'Hide log',
      affect: 'affect',

      // IncidentsListWrapper.js
      'Ongoing incidents': 'Ongoing incidents',

      // NoIncident.js
      'Not much is happening here': 'Not much is happening here',

      // IncidentsNoIncidents.js
      'No incidents in the last 48 hours': 'No incidents in the last 48 hours',
    },
  },
  no: norwegian,
  nb: norwegian,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    //lng: 'no',
    fallbackLng: 'en',
    detection: { order: ['navigator'] },
    debug: true,
    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false, // This to allow : (colon) in the translation
    namespaceSeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
