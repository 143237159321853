import React from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import '@dnb/eufemia/style';
import { Tabs } from '@dnb/eufemia/components';

import HomePage from './HomePage';

import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Loading from './components/layout/Loading';

import useStatusPage from './hooks/useStatusPage';
import History from './views/history/History';
import Incidents from './views/Incidents';

import './dnbstatus-styles.css';
import { useTranslation } from 'react-i18next';

import { extractFirstPartOfPath } from './helpers';

// Importing the configuration for which page to use
const config = require('./config.json');
//const config = require('./dev.json')


const statusPageConfigPersonal = {
  //Preprod page: 'cr0lphtyqjz0', //Production page: 'n3pkrn6d6qtk'
  page: config.page,

  getIncidents: true,
  incidentsType: '',
  getComponents: true,
  getScheduledMaintenances: true,
  scheduledMaintenancesType: { in_progress: true, scheduled: true, completed: false }, // Changed to array active, upcoming or '' which will give everything
  updateInterval: 30000, //ms
  includedComponents: {
    //#PROD
    //Innlogging
    l2lgzys2gz88: 'Innlogging', //Group
    tnw56bcc739s: 'BankID',
    '1dm46zl9zk0z': 'BankID på mobil for Telenor',
    '8b23mx7mnkcv': 'BankID på mobil for Telia',
    xyynf61zkx6m: 'BankID på mobil for Ice.net',
    hfq6c7k81w89: 'BankID (OIDC)',
    '1pfztystjrzk': 'BankID App',
    l3t64spqnfzn: 'DigiPass',
    cttbsnk1nnk4: 'GO3Phonecode',

    //Nettbank
    rtdlds1sbwl7: 'Nettbank', //Group
    gtsl57dxb26f: 'Kontoer/balanse Nett',
    y6ng73k0nqp8: 'Overføringer Nett',
    przh27kqfg7w: 'Betalinger Nett',

    //Mobilbank
    '4klj7trfzvp5': 'Mobilbank', //Group
    '4yvx39ckc5jy': 'Betalinger Mobil',
    qkxyg79t7d7g: 'Kontoer/Balanse Mobil',
    txjq2s9926sw: 'Overføringer Mobil',
    ypxdcjjrsvcr: 'Regninger Mobil',

    //Kort
    c3cvf4lsnfzg: 'Minibanker, kort og betalingsterminaler', //Group
    '0gtsdyvskqng': 'Kort',

    //Spare
    '6dldw262pd9z': 'Spare',
    '9mdp0dscmp7z': 'Pensjonskonto Flex',

    //Andre tjenester
    ldbl8vk30rb6: 'Andre tjenester privat',
    '1n9zpt1sh5dh': 'Andre tjenester privat',

    // Sbanken Mobilbank
    '1zl3jrflgc9t': 'Sbanken Mobilbank', //Group
    'ck6y3x1fmz7q': 'Betalinger Mobil',
    'jdxr3vjj5d6b': 'Kontoer/Balanse Mobil',
    'p5151pwllfd3': 'Overføringer Mobil',
    '9n1z2j6zpfw5': 'Regninger Mobil',

    //#Test / Dev
    // Nettbank
    wl494vvmvckp: 'Nettbank',
    ndlfykymrw0v: 'Kontoer/balanse Nett',
    // Kort
    hdyvd0v0w8gh: 'Minibankenr',
    '603y3yfkdhf3': 'Kort',
    //Mobilbank
    wcfk3rn3hpdl: 'Mobilbank',
    vjbyt2zktkrm: 'Betalinger Mobil',
    wds7kg6y5prc: 'Kontoer/Balanse Mobil',
    gyg6gyl54cnv: 'Overføring Mobil',

    //Sbanken Mobilbank
    'z08v9zqhc37g': 'Sbanken Mobilbank', //Group
    'hjrc9m2drl1t': 'Betalinger Mobil',
   'fql7qp08r937': 'Kontoer/Balanse Mobil',
    // Innlogging

    // Innlogging
    z2fqtbpsh52z: 'Innlogging',
    qsytltngrx8r: 'BankID mob TN',
    '2rt3lmgbsy7v': 'BankID mob TL',
    cqd3nnsblbyh: 'BankID mob ICE',
    md8v5vg7xc9k: 'BankID OIDC',
    stv8gcy7fxxh: 'BankID App',
    kv3m47y6d6f2: 'BankID',

    //Andre tjenester privat
    '49g1fjyryvkb': 'Andre tjenester privat', //Parent
    gkcsc9yk7grw: 'Andre tjenester privat',
  },
};


const statusPageConfigBusiness = {
  page: config.page,
  getIncidents: true,
  incidentsType: '',
  getComponents: true,
  getScheduledMaintenances: true,
  scheduledMaintenancesType: { in_progress: true, scheduled: true, completed: false }, // Changed to array active, upcoming or '' which will give everything
  updateInterval: 30000, //ms
  includedComponents: {
    // #PROD#
    // DNB Bedrift (app)
    'h14z7m281186': 'DNB Bedrift (app)', //Group
    '11b0x4pr6xb2': 'Payments', //Group

    //Corporate internet banking
    xlnqvn1tr7zb: 'Corporate Internet banking',
    '8y74k6rbjrqm': 'Transactions',
    l465c623dflm: 'Payments Overview',
    '5bx84bcgntst': 'Payments',
    z9w32g0yr69f: 'Account Overview',
    'tn52rl4sqb2x': 'International Payments',
    //Innlogging
    l2lgzys2gz88: 'Innlogging', //Group
    tnw56bcc739s: 'BankID',
    '1dm46zl9zk0z': 'BankID på mobil for Telenor',
    '8b23mx7mnkcv': 'BankID på mobil for Telia',
    xyynf61zkx6m: 'BankID på mobil for Ice.net',
    hfq6c7k81w89: 'BankID (OIDC)',
    l3t64spqnfzn: 'DigiPass',
    cttbsnk1nnk4: 'GO3Phonecode',
    '1pfztystjrzk': 'BankID App',

    //Andre tjenester bedrift
    'xdhm469t57s2': 'Andre tjenester bedrift',
    'x4dfh4vtjt2p': 'Andre tjenester bedrift',

    //#Test / Dev
    // Innlogging
    z2fqtbpsh52z: 'Innlogging',
    qsytltngrx8r: 'BankID mob TN',
    '2rt3lmgbsy7v': 'BankID mob TL',
    cqd3nnsblbyh: 'BankID mob ICE',
    md8v5vg7xc9k: 'BankID OIDC',
    stv8gcy7fxxh: 'BankID App',
    kv3m47y6d6f2: 'BankID',
    // Corporate internet banking
    vcn6bh1rx3yg: 'Corporate internet banking',
    '7vg5p63crcvg': 'International Payments',

    // Andre tjenester bedrift
    jtzztlxn7p9y: 'Andre tjenester bedrift', //Parent
    mj2lgfg7yn0d: 'Andre tjenester bedrift',
  },
};

export default function App() {
  return (
    <>
      <Router>
        <Header />
        <Content />
      </Router>
      <Footer />
    </>
  );
}

const Content = withRouter(({ history, location }) => {
  const personal = new useStatusPage(statusPageConfigPersonal);
  const business = new useStatusPage(statusPageConfigBusiness);

  /*if (process.env.NODE_ENV !== 'production') {
        // Only runs in development and will be stripped in production builds.
      }*/
  //const { groups, incidents, maintenances, updateTimestamp } = useStatusPage(statusPageConfig);
  //const { personal: {groups, incidents, maintenances, updateTimestamp }} = useStatusPage(statusPageConfig);
  const { t } = useTranslation();

  return (
    <div className="dnb-center-content">
      <Tabs
        data={[
          { title: t('Personal'), key: '/' },
          { title: t('Business'), key: '/business' },
        ]}
        tabs_style="mint-green"
        selected_key={extractFirstPartOfPath(location.pathname)}
        on_change={({ key }) => history.push(key)}
        align="left"
      >
        <Switch>
          <Route path="/incidents">
            <Incidents groups={personal.groups} incidents={personal.incidents} />
          </Route>
          <Route path="/business/incidents">
            <Incidents groups={business.groups} incidents={business.incidents} />
          </Route>
          <Route path="/history">
            {personal.groups.length > 0 ? (
              <History groups={personal.groups} incidents={personal.incidents} />
            ) : (
              <div>{t('Loading')}...</div>
            )}
          </Route>
          <Route path="/business">
            {business.groups.length > 0 ? (
              <HomePage
                groups={business.groups}
                incidents={business.incidents}
                maintenances={business.maintenances}
                updateTimestamp={business.updateTimestamp}
                includedComponents={statusPageConfigBusiness.includedComponents}
              />
            ) : (
              <Loading />
            )}
          </Route>
          <Route path="/" default>
            {personal.groups.length > 0 ? (
              <HomePage
                groups={personal.groups}
                incidents={personal.incidents}
                maintenances={personal.maintenances}
                updateTimestamp={personal.updateTimestamp}
                includedComponents={statusPageConfigPersonal.includedComponents}
              />
            ) : (
              <Loading />
            )}
          </Route>
        </Switch>
      </Tabs>
    </div>
  );
});
